
.formDelete {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    left: 600px;
    top:-70px;
  }
  
  .formWrapperDelete {
    padding: 20px;
    position: fixed;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
    height: 70vh;
    width:60vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index:0;
  }

  .rclose {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: black;
  }
 
 

  .scroll {
    width: 100%;
    height: 100%;
    overflow: scroll;
    top: 20px;
 }
 .scroll::-webkit-scrollbar {
     width: 12px;
 }
 
 .scroll::-webkit-scrollbar-track {
     border-radius: 10px;
 }
 
 .scroll::-webkit-scrollbar-thumb {
     border-radius: 10px;
 }

 .scroll ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.scroll ul li {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}
.scroll ul li:hover {
    background-color: #ece8ff;
}

