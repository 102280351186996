.chart-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .controls-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 90%;
    margin-bottom: 20px;
  }
  
  .controls-container label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .chart-container canvas {
    width: 100% !important;
    height: 500px !important; /* Taille fixe du graphique */
  }
  
  .modal-content {
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-top: 50px;
  }