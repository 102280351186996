


/* ================================================Bill styling */

.bill-header{
    border-bottom: 2px dotted rgb(77, 76, 76);
}
.bill-model h1{
    font-size: 30px;
}
.bill-model p{
    margin-top: 0;
    margin-bottom: 0 !important;
  
}
.dotted-border{
    margin: 5px 0;
    border-bottom: 2px dotted rgb(77, 76, 76);
}
.bill-model h2{
    margin-bottom: 0 !important;
}


.left {
    margin-left: 15px;
}
.right{
    margin-top: -12px;
}

.ant-table table { font-size: 10px; }