

.wrapper{
    width: 600px;
    height: 200px;
    -webkit-box-shadow: 0px 0px 17px -11px rgba(0,0,0,0.58); 
    box-shadow: 0px 0px 17px -11px rgba(0,0,0,0.58);
    background-color:white;
    margin:10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.title{
    align-items: center;
    justify-content: center;
    font-size: 15;
    color: black;
    text-transform: capitalize;
    margin-top: 10px;
}

.panneau{
    width: 100%;
    height: 40px;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
}

h1{
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    color: gray;
}

.prix{
    color: crimson;
    font-size: 25px;
}

