

.content{
    display: flex;
    flex-direction: column;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px;
    padding:20px;
}

.Wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.searchContainer{
    width: 100%;
    height: 100px;
}

.tableData{
    margin-top: 50px;
    width: 100%;
    height: 100vh;
}

.infoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
