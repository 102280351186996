

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right:20px
}

.userListDelete{
    color: red;
    cursor: pointer;
}



.bill-header{
    border-bottom: 2px dotted rgb(77, 76, 76);
}
.bill-model h1{
    font-size: 30px;
}
.bill-model p{
    margin-top: 0;
    margin-bottom: 0 !important;
  
}
.dotted-border{
    margin: 5px 0;
    border-bottom: 2px dotted rgb(77, 76, 76);
}
.bill-model h2{
    margin-bottom: 0 !important;
}


.left {
    margin-left: 15px;
}
.right{
    margin-top: -12px;
}

.ant-table table { font-size: 10px; }